.navigationBar {
  // navbar width in desktop mode: collapsed & expanded
  $expandedWidth: 240px;
  $collapsedWidth: 88px;

  // navbar height in responsive (tablet/mobile) mode: collapsed & open
  $closedHeight: 75px;

  grid-area: navigationBar;

  width: $expandedWidth;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;

  @media screen and (min-width: 1024px) {
    &.collapsed {
      width: $collapsedWidth;

      .logo {
        opacity: 0;
        width: 0;
        pointer-events: none;
      }
    }

    &.menuMode {
      visibility: hidden;
    }

    .menuDesktop {
      display: flex;
      align-items: center;
      justify-content: start !important;
    }
  }

  @media screen and (max-width: 1023px) {
    width: 100vw;
    max-height: $closedHeight;
    overflow: hidden;

    &.open {
      height: 100vh;
      max-height: 100%;
    }

    &.menuMode {
      visibility: visible;
    }

    .menuMobile {
      display: flex;
      align-items: start;
      justify-content: start !important;
    }
  }
}
