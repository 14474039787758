.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  transition: border 0.24s ease-in-out;
  max-height: 76px;

  &.isDragActive,
  &:focus {
    border-color: #2196f3;
    opacity: 0.7;
  }
}

.fileWrapper {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.files {
  max-height: 70vh;
  overflow-y: scroll;
}
