.results {
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;

  .header {
    border-bottom: 1px solid #d6d6d6;
  }
}
