.cell {
  display: flex;
  align-items: center;
  line-height: 1.1rem;
  overflow: hidden;
}

.cellEditor {
  background-color: unset !important;
}

.row {
  &:hover {
    background-color: #cff4e0;

    & > .action {
      display: visible;
    }
  }

  &:hover .action {
    opacity: 1;
  }

  .action {
    opacity: 0;
    transition: all 0.3s ease-out;

    .removeButton {
      color: #f44336;
    }
  }
}
