.sidebar {
  position: relative;
  overflow: hidden;

  .insuredPersonSummary {
    align-items: center;
    display: inline-flex;
  }

  .partnerHeader {
    position: relative;
    box-sizing: border-box;

    & > span:first-of-type {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .name {
    display: inline-flex;
    align-items: center;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    position: relative;

    .separator {
      width: 1px;
      background: #eaeaea;
    }

    button {
      font-weight: 400;
      position: relative;
    }
  }

  .tariffs {
    .tariffCalculation {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tariffChip {
        background: #f0e648;
        color: #00635f;
        font-weight: 500;

        &:before {
          display: none;
        }
      }

      &:hover {
        .tariffPrice .deleteAction {
          width: auto;
          opacity: 1;
          margin-left: 8px;
        }
      }

      .tariffPrice {
        display: flex;
        align-items: center;

        .risk {
          border-right: 1px solid #eaeaea;
          display: inline-block;
        }

        .price {
          display: inline-block;
        }

        .deleteAction {
          opacity: 0;
          width: 0;
          transition: all 0.3s ease-out;
        }
      }
    }
  }

  .errorContainer {
    display: flex;
    color: white !important;
    border-radius: 2px;

    .textContainer {
      display: flex;
      flex-direction: column;
    }
  }
}
